.PlayerMessageWholeMessageBox{
    direction: rtl;
    padding-top: 1cm;
    padding-bottom: 1cm;
    padding-right: 0.5cm;
    padding-left: 0.5cm;
}

.PlayerMessageBox{

    border-radius: 8px;
    min-height: 10rem;
    width: 100%;
    border: 0.3mm solid #17494d; 
    padding-bottom: 3mm;
}

.PlayerMessageContent{
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #17494d;
    border-bottom: #17494d 0.1mm solid;
    padding-top:2mm;
    padding-bottom: 2mm;
    font-weight: bolder;
    font-size: 23px;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

.PlayerMessageAuthor{
    padding-right: 3mm;
    padding-top: 2mm;
    padding-bottom: 2mm;
    color: #17494d;
    font-size: 19px;
    font-weight: bold;
}

.PlayerMessageMessageTitle{
    padding-right: 3mm;
    padding-top: 2mm;
    padding-bottom: 2mm;
    padding-left: 3mm;
    color: #17494d;
    font-size: 19px;
    font-weight: bold;
}

.PlayerMessageInput{
    margin-top: 3mm;
    height: 15rem;
    vertical-align: top;
    border-radius: 4px;
}

.PlayerMessageSendContainer{
    padding-top: 3mm;
    padding-left: 3mm;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.PlayerMessageSendButton{
    width: 8rem;
    height: 3rem;
    background-color: #17494d;
    color: white;
    font-size: 21px;
    font-weight: bolder;
    text-align: center;
    align-items: center;
    border: 0ch solid transparent;
    border-radius: 5px;
}

.PlayerMessageSendButton:active{
    background-color: #051717;
    transition: ease-in 3ms;
}