.Article-Tabs-Container{
  min-height: 40rem;
  padding-top: 2cm;
  padding-left: 12%;
  padding-right:12%;
  padding-bottom: 2cm;
  direction: rtl;
  color: #17494d !important;
}

.Article-Tab{
  background-color: white;
  border-radius: 5px;
  width: 100%;
  min-height: 1.8cm;
  border: #17494d 0px solid;

  margin-top: 0.3cm;
  margin-bottom: 0.3cm;
}

.Article-Active-Tab{
  background-color:#17494d;
  color: white;
  border-radius: 5px;
  width: 100%;
  min-height: 1.8cm;
  border: #17494d 0px solid;
  margin-top: 0.1cm;
  margin-bottom: 0.1cm;
}

.Article-Tab:hover{
  transition: ease-in-out 0.5s;
  background-color: #17494d;
  color: white;
}


.Article_Group_Article{
  font-weight: bolder;
  color: black;
  padding-bottom: 0.6cm;
}

.Article-Contents{
  font-size: 30px;
  font-weight: bolder;
  color: rgb(23, 23, 23);
  padding-bottom: 1cm;
}

.Article-Text{
  text-align: justify;
  font-size: 18px;
  color: rgb(23, 23, 23);
}

.Article-Article-Body{
  padding-right: 5%;
  padding-left: 5%;
}

.Article-DropDownContainer{
  text-align: center;
}

.Article-DropDown{
  background-color: white;
  color:#17494d;
  font-size: 18;
  font-weight: bold;
  width: 95%;
  justify-self: center;
  align-self: center;
  height: 3rem;

}

.Article-DropDown-Items{
  background-color:#17494d;
  color:white;
  font-size: 18;
  font-weight: bold;
  width: 95%;
  justify-self: center;
  align-self: center;
  height: 3rem;

}

.Article-Collapse-Button{
  min-height: 2rem;
  font-size: 18px;
  border: 0ch white none;
  width: 100%;
  padding-bottom: 10mm;

}


.t1{
  text-align: right;
}

.dropDownShape{
  animation: append-animate .3s linear;
}

@keyframes append-animate {
	from {
		transform: scaleY(0);
		opacity: 0;
	}
	to {
		transform: scaleY(1);
		opacity: 1;	
	}
}