.AdminMessageWhole{
    padding-bottom: 5mm;
    padding-left: 1cm;
    padding-right: 1cm;
}

.AdminMessageNumber{
    padding-top: 2mm;
    padding-bottom: 2mm;
    padding-right: 5mm;
    text-align: right;
    background-color: #17494d;
    font-size: 20px;
    font-weight: bold;
    color: white;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.AdminMessageBox{
    min-height: 10rem;
    border: 0.3mm solid #17494d;
    border-radius: 7px;
}

.AdminMessageSender{
    padding-top: 2mm;
    padding-bottom: 2mm;
    direction: rtl;
    padding-right: 7mm;
    font-size: 16px;
    font-weight: bold;
    color: #17494d;
}

.AdminMessageMessage{
    text-align: justify;
    text-justify: auto;
    padding-top: 2mm;
    padding-bottom: 3mm;
    direction: rtl;
    padding-right: 5mm;
    padding-left: 5mm;
    font-size: 16px;
    color: black;
    text-align: right;
    word-wrap: break-word;
}

.AdminMessageReply{
    border-radius: 5px;
    border: #17494d 0.1mm solid;
    height: 8rem;
    width: 93%;
    direction: rtl;
    font-size: 14px;
}

.AdminMessageSendButton{
    background-color: #17494d;
    font-weight: bold;
    color: white;
    border: 0px transparent solid;
    border-radius: 7px;
    width: 6rem;
    height: 3rem;
    margin-bottom: 2mm;
}

.AdminMessageSendButton:hover{
    transition: ease-in-out 3ms;
    background-color: #0a292b;
}

.AdminMessageSendButton:active{
    transition: ease-in-out 3ms;
    background-color: #020c0c;
}

.AdminMessageReplyMessages{
    width: 93%;
    direction: rtl;
    font-size: 14px;
    color: black;
    text-align: right;
    text-align: justify;
    text-justify: auto;
    word-wrap: break-word;
    padding-left: 3mm;
}