.Helps-textContainer{
  background-color: transparent;
  min-height: 300px;
  padding-top: 2cm;
  padding-left: 12%;
  padding-right:12%;
  padding-bottom: 2cm;
  direction: rtl;
  color: #17494d;
  font-size: 25px;
  font-weight: bold;
}

.Helps-Button{
  background-color: white;
  width: 100%;
  height: 100%;
  color: #17494d;
  border-radius: 5px;
  border-color: #17494d;
}

.Helps-Button:hover{
  transition: ease-in-out 0.3s;
  background-color: #17494d;
  color: white;
}

.Helps-ButtonContainer{
  height: 85px;
  padding: 5px 5px 5px 5px;
}