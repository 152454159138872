.AdminPagination{padding-top: 0.3cm;
  padding-bottom: 0.3cm;
  margin-top: 0.5cm;
  text-align: center;
  align-items: center !important;
  align-content: center !important;
  justify-items: center !important;
  justify-content: center !important;
  justify-self: center;
  color: white;
  font-size:17px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}