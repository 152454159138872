.LandingPage-textContainer{
    background-color: transparent;
    min-height: 600px;
    padding-top: 2cm;
    padding-left: 0.5cm;
    padding-right:0.5cm;
    padding-bottom: 2cm;
    font-size: 18px;
    color: black;
    width: 100%;
    text-overflow: clip;
    word-wrap: break-word;
    white-space: pre-wrap;
    direction: rtl;
}

.downloadContainer{
    width: 100%;
    text-align: center;
    padding-top: 3rem;
}

